<template>
  <l-feature-group v-if="showDetection">
    <!-- Detection sector for directional sensor, note this is only a 90 degree sector -->
    <template v-if="isDsxModelDirectional(sensor.model)">
      <l-polygon
        data-testid="detection-sector"
        :visible="visible"
        :weight="weight"
        :opacity="1"
        :lat-lngs="range"
        :color="detectionColor"
        :fill-color="detectionColorFill"
        :fill-opacity="fillOpacityDirectional"
        v-if="!allSectorsActive && this.drone"
        @click="onSelect"
      />

      <!-- detection sector for FHSS-->
      <l-polygon
        data-testid="detection-sector"
        :visible="visible"
        :weight="weight + 0.5"
        :opacity="1"
        :lat-lngs="fhssRange"
        :color="detectionColor"
        :fill-color="detectionColorFill"
        :fill-opacity="0"
        @click="onSelect"
        v-if="!this.drone"
        :dashArray="fhssDashArray"
      />

      <!-- For Nearby detections -->
      <l-circle
        :radius="150"
        :visible="visible"
        :weight="weight"
        :opacity="0.5"
        :lat-lng="[sensor.latitude, sensor.longitude]"
        :color="detectionColor"
        :dashArray="fhssDashArray"
        :fill-color="detectionColorFill"
        :fill-opacity="fillOpacityDirectional"
        v-if="allSectorsActive"
        @click="onSelect"
        :className="`rfZeroDetection`"
      />
    </template>

    <!-- Detection for omni sensor -->
    <l-circle
      :className="`rfZeroDetection`"
      data-testid="detection-sector"
      v-if="!isDsxModelDirectional(sensor.model)"
      :visible="visible"
      :color="detectionColor"
      :fill-color="detectionColorFill"
      :fill-opacity="fillOpacityDirectional"
      :radius="sensor.reach"
      :weight="weight"
      @click="onSelect"
      :lat-lng="[sensor.latitude, sensor.longitude]"
    />
  </l-feature-group>
</template>

<script>
import utils from '../utils'
import { mapGetters } from 'vuex'
import { LCircle, LFeatureGroup, LPolygon } from 'vue2-leaflet'
import {
  isDsxModelDirectional,
  isDsxModelJamming
} from '@/store/modules/rf_sensors/types'
import { getTheme } from '@/components/Map/utils/themes'
import { displayRadiatingSector } from './radiatingSector'
import { EDetectionState } from "@/store/modules/detection/types";

const props = {
  visible: {
    type: Boolean,
    default: false
  },
  azimuth_std_rad: {
    type: [String, Number],
    default: 0
  },
  azimuth_rad: {
    type: [String, Number],
    default: 0
  },
  correlation_key: {
    type: [String, Number]
  },
  rf_sensor_id: {
    type: [String, Number]
  },
  activeBeam: {
    type: [String, Number]
  },
  activeRfId: {
    type: Number
  },
  protocol: {
    type: String,
    default: ''
  },
  signal_bars: {
    type: Number
  },
  state: {
    type: String,
    default: 'unfiltered'
  },
  drone: {
    type: Boolean,
    default: true
  }
}

const SECTOR_MID_OFFSET = 22.5

export default {
  name: 'RfDetectionSector',
  props,
  data: () => ({
    watcher: null,
    isDsxModelJamming,
    isDsxModelDirectional
  }),
  components: {
    LFeatureGroup,
    LCircle,
    LPolygon
  },
  computed: {
    ...mapGetters('rf_sensors', [
      'dsxSensorById',
      'allDsxSensorsInSentry',
    ]),
    ...mapGetters('cameras', ['allCameras']),
    ...mapGetters('sentries', ['sentriesSet']),
    key() {
      return `${this.correlation_key}:${this.rf_sensor_id}`
    },
    showDetection() {
      const display720 = this.sensor.show_720_detection
      if (!display720 && this.allSectorsActive) return false
      return true
    },
    fhssDashArray() {
      if (!this.drone) return this?.theme?.fhss?.dashArray || ''
      return ''
    },
    isSelected() {
      return this.activeBeam === this.key
    },
    fillOpacityDirectional() {
      return (this.signal_bars / 10 || 0.4) / 2 //fill opacity will change depending on signal strength, this is why don't use the theme
    },

    weight() {
      let unselectedWeight = this.theme?.detection?.lineWeight?.unselected
      if (!this.drone) unselectedWeight += 0.5 //make it obvious that its not a drone detection
      return this.isSelected
        ? this.theme?.detection?.lineWeight?.selected || 2.0
        : unselectedWeight || 0.3
    },
    themeName() {
      return this.$store.state.theme
    },
    theme() {
      return getTheme(this.themeName)
    },
    allSectorsActive() {
      return this.azimuth_rad > 12
    },
    detectionColor() {
      switch (this.state) {
        case EDetectionState.Whitelisted:
          return this.theme.detection.sectorColorWhitelisted
        default:
          return this.theme.detection.sectorColor
      }
    },
    detectionColorFill() {
      switch (this.state) {
        case EDetectionState.Whitelisted:
          return this.theme.detection.sectorFillWhitelisted
        default:
          return this.theme.detection.sectorFill
      }
    },
    sensor() {
      return this.dsxSensorById(this.rf_sensor_id) || {}
    },
    detectionAngle() {
      return (this.azimuth_rad * 180) / Math.PI
    },
    sectorPolygon() {
      return isLine => {
        const { latitude: lat, longitude: lng, reach } = this.sensor

        return lat
          ? utils.makeArcPathFromRfDetection({
              azimuth_std_rad: this.azimuth_std_rad,
              azimuth_rad: this.azimuth_rad,
              vertex: { lat, lng },
              reach: reach / 2,
              show_sector_as_line: isLine
            })
          : []
      }
    },
    range() {
      const { reach } = this.sensor || {}
      const min = -SECTOR_MID_OFFSET
      const max = SECTOR_MID_OFFSET
      return reach
        ? utils.createRange({
            reach,
            direction: this.detectionDirection(this.azimuth_rad),
            min,
            max,
            vertex: [this.sensor.latitude, this.sensor.longitude]
          })
        : []
    },
    fhssRange() {
      const { reach } = this.sensor || {}
      const min = -SECTOR_MID_OFFSET + 8
      const max = SECTOR_MID_OFFSET - 8
      return reach
        ? utils.createRange({
            reach: reach * 0.9,
            direction: this.detectionDirection(this.azimuth_rad),
            min,
            max,
            vertex: [this.sensor.latitude, this.sensor.longitude]
          })
        : []
    },
    angleDifference() {
      const { direction: s_deg } = this.sensor
      const d_deg = (this.azimuth_rad * 180) / Math.PI
      return ((d_deg - s_deg + 540) % 360) - 180
    },
    withinExtendedRange() {
      return (
        Math.abs(this.angleDifference) < 70 &&
        !this.anyRfSensorOverlap(this.positiveAngle ? 'right' : 'left')
      )
    },
    positiveAngle() {
      return Math.sign(this.angleDifference) > 0
    },
    isOutside() {
      return this.angleDifference >= 45 || this.angleDifference <= -45
    },
    outsideDetection() {
      return angle => {
        const [min, max] = this.positiveAngle
          ? [45, 45 + angle]
          : [-45 - angle, -45]
        const { latitude, longitude, reach, direction } = this.sensor
        return utils.createRange({
          reach,
          direction,
          min,
          max,
          vertex: [latitude, longitude]
        })
      }
    },
    otherRfSensors() {
      return this.allDsxSensorsInSentry(
        this.sentriesSet[this.sensor.sentry_id]
      ).filter(sensor => sensor.id !== this.sensor.id)
    },
    cameraRfTracked() {
      return (
        this.allCameras.length &&
        this.allCameras[0].rf_tracked_target === this.correlation_key
      )
    }
  },
  methods: {
    detectionDirection(azimuthRad) {
      const detectionDir = Math.round((azimuthRad * 180) / Math.PI)
      // it's already the absolute direction including the sentry offset
      return (this.sensor.direction + detectionDir) % 360
    },
    onSelect() {
      this.$emit('selectBeam', this.key)
    },
    scaleBetween(unscaledNum, minAllowed, maxAllowed, min, max) {
      return (
        ((maxAllowed - minAllowed) * (unscaledNum - min)) / (max - min) +
        minAllowed
      )
    },
    // TODO: Handle this on the backend, maybe add a sensor attribute ignoreOutsideLeft...
    anyRfSensorOverlap(side = 'left') {
      return this.otherRfSensors.some(rfsensor => {
        const otherDirection = utils.circularBounds(rfsensor.direction, [
          0,
          360
        ])
        const sensorNeighBourDirection = utils.circularBounds(
          this.sensor.direction + (side === 'left' ? -90 : 90),
          [0, 360]
        )
        return sensorNeighBourDirection === otherDirection
      })
    },
    blinkRfDetection(action = true) {
      const detectionRef = [
        this.$refs[`rfds-${this.correlation_key}`],
        this.$refs[`rfdl-${this.correlation_key}`]
      ]
      detectionRef
        .map(dref => dref && dref.mapObject._path)
        .filter(p => p)
        .forEach(path => {
          action
            ? path.classList.add('blinkMap')
            : path.classList.remove('blinkMap')
        })
    }
  },
  mounted() {
    this.$nextTick(() => {
      displayRadiatingSector(this.detectionColorFill)
      if (this.cameraRfTracked) this.blinkRfDetection()
    })
  },
  watch: {
    cameraRfTracked(isTracking) {
      this.blinkRfDetection(isTracking)
    }
  }
}
</script>

<style>
.rfZeroDetection {
  fill-opacity: 0.4 !important;
  fill: url(#rfZeroDetection) !important;
}
.rfOutsideDetection {
  fill-opacity: 0.2 !important;
  fill: url(#rfOutsideDetection) !important;
}
</style>
